import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/env-provider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/use-env-context.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LEGACY_ENV_KEY"] */ "/app/src/app/components/LegacyEnvVariables/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/PageLayoutV3/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/utils/getLocaleFormatting.tsx");
